.displayBlock{
    display: block !important;
}
.textAlignCenter{
    text-align: center;
}

.customRow1{
    display:block;
    text-align:center;
    width:70%;
    margin:auto
}
.customRow2{
    justify-content:space-evenly;
    width:90%;
    margin:auto;
    text-align:center;
}
.visibilityHidden{
    visibility: hidden;
}
.alignItemsCenter, .alignItemsCenter div{
    align-items: center;
}