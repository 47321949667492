/* pricing page */

.rangeSlider .wrapper {
    color: #4b4949; 
    max-width: 600px;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
.rangeSlider .content {
    width: 100%;
    max-width: 500px;
    height: 80%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
.rangeSlider .range {
    display: block;
    align-items: center;
    margin-bottom: 1rem;
  }
  

  #range1 {
    -webkit-appearance: none;
    appearance: none; 
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
  }
  
  #range1::-webkit-slider-runnable-track {
    height: 15px;
    background: #ccc;
    border-radius: 16px;
  }
  
  #range1::-moz-range-track {
    height: 15px;
    background: #ccc;
    border-radius: 16px;
  }
  
  #range1::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #1781FB;
    box-shadow: -407px 0 0 400px #1781FB;
  }
  
  #range1::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #1781FB;
    box-shadow: -407px 0 0 400px #1781FB;
  }



/*  PRIcing CArd */
    h1 {
        text-align: center;
        }

    .container {
        display: flex;
        margin: 20px auto;
        width: 70%;
    }

    .pricingCard {
        border: 1px solid gray;
        background: #fff;
        border-radius: 20px;
        flex: 1;
        margin: 10px;
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        text-align: center;
        min-width: 240px
    }

    .pricingCard h2 {
        margin-bottom: 0;
    }

    .title {
        background: gray;
        padding: 20px 25px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        font-size: 1.7em;
    }

    .pricingCard.featured {
        color: #C51852;
        border-color: #C51852;
        background: #F4F5F8;
    }

    .pricingCard.featured .title {
        background: #C51852;
        color: white;
    }

    .pricingCard.featured .bttn {
        background: #C51852;
        color: white;
        &:hover {
            background: gray;
        }
    }

    .pricingCard .price-overview {
        margin-top: 20px;
        font-weight: bold;
    }

    .pricingCard.featured .btn {
        background: gray;
    }

    .price .price__currency {
        font-size: 1.7em;
        padding-right: 3px;
    }
    .price .price__dollar {
        font-size: 1.7em;
    }
    .price .price__cent {
        font-size: 1em;
    }

    .cardP {
        padding: 10px 25px 20px;
        border: 0px;
    }

    .price-desc {
        font-size: 0.7em;
        margin-top: 0;
    }

    .bttn {
        display: block;
        text-align: center;

        position: relative;
        margin: 0 25px;
        margin-top: auto;

        background: none;
        border: 1px solid #C51852;
        color: #C51852;
        padding: 5px;
        border-radius: 20px;

        &:hover {
            background: #F4F5F8;
            text-decoration: none;
        }
    }

    .bttn {
        text-decoration: none;
    }

    .toggle-container {
        text-align: center;
    }
    .paidTag {
        background-color: #4CAF50; /* Green background color */
        color: white;             /* White text color */
        padding: 5px 10px;        /* Padding for better appearance */
        border-radius: 5px;       /* Rounded corners */
        font-size: 14px;          /* Adjust font size as needed */
        margin-bottom: 10px;      /* Add some spacing between the tag and other elements */
        display: inline-block;    /* Display as inline-block to only take necessary space */
        width: 60%;
        margin: auto;
      }