
main {
    min-height: 90vh;
}

h1 {
    font-size: 1.8rem;
    padding: 1rem 0;
}

h2 {
    font-size: 1.4rem;
    padding: 0.5rem 0;
}

h3 {
    padding: 1rem 0;
}

.form-control {
    height: 80px !important;
    width: 500px !important;
    font-size: 17px !important;
    font-family: Open Sans Regular;
    font-weight: normal;
    text-align: center;
    padding: 54px !important;
    border: 1.5px solid #e20d57 !important;
    display: flex;
    margin: auto !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}

.form-control.focus {
    color: rgba(0, 0, 0, 1);
    outline: none;
    border-bottom: 10px solid #e20d57;
}

.flag-dropdown {
    border: 1.5px solid #e20d57 !important;
    /* padding: 54px !important; */

    display: flex;
    margin: auto !important;

    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
}

.selectedRow{
    background: #F7F7F7;
}
.inactiveRow{
    opacity: 0.5;
}

.filter-row{
    display: flex;
    justify-content: space-between;
    margin-top: 4%;
    background: #f2ecef;
    padding: 2.5%;
    cursor: pointer;
}

.closeIcon{
    width: 1em !important;
}
.justifyContentCenter{
    justify-content: center;
}

/* Tell us about yourself - Questions part*/
@import url(https://fonts.googleapis.com/css?family=Roboto);

#listSelect {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    text-align: initial;
}
#listSelect ul {
    list-style: none;
    padding-left: 0;
}
#listSelect ul li {
  list-style: none;
  padding: 7px 10px;
  margin: 10px 0;
  border-radius: 4px;
  background: #F6F6F6;
  color: #22222250;
  border: 2px solid #F6F6F6;
  -webkit-user-select: none !important;
  cursor: pointer;
}
#listSelect ul li:hover {
    border: 2px solid #D4D4D4;
    color: #222222;
}
#listSelect ul li.selected {
    background: #841E5A;
    border: 2px solid #841E5A;
    color: #FFFFFF;
}
.tick-icon {
    float: right;
}
#listSelectOther {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    text-align: initial;
}
#listSelectOther li {
  list-style: none;
  padding: 7px 10px;
  margin: 10px 0;
  border-radius: 4px;
  background: #F6F6F6;
  color: #22222250;
  border: 2px solid #F6F6F6;
  -webkit-user-select: none !important;
  cursor: pointer;
}
#listSelectOther li:hover {
    border: 2px solid #D4D4D4;
    color: #222222;
}
#listSelectOther li.selected {
    background: #841E5A;
    border: 2px solid #841E5A;
    color: #FFFFFF;
}
.stickyHeader{
    position: sticky;
    z-index: 9999;
    top: 0px;
}
/* confirming modals style */
.subtitleActionModal{
    padding: 16px 26px;
    background-color:white;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
}

/* No companies section */
.centeredContent{
    width: 40%;
    top: 37%;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: inherit;
}
.centeredContent p:first-child{
    width: 80%;
    font-weight: bold;
    font-size: 30px;
    line-height: initial;
}
.display-block {
    display: block;
}
.display-none {
    display: none;
}
.fa-dropdown {
    background-color: #FFFFFF !important;
    margin: 5px auto 0;
    float: right;
}
/* .dropdown-content {
    height: 254px;
    transition: height 0.4s ease-out;
} */

/* @media only screen and (min-width: 1300px) { 
    .dropdown-content {
        height: 300px;
    }
} */

/* sorting arrows */
.bold-text{
    font-weight: bold;
}
.sortableTable p span{
    display: block;
    font-weight: bold;
}

/* freezedColumns */
.freezedColumn1{
    position: sticky;
    left: 0;
    background-color: #fff;
    overflow: hidden;
}
.freezedColumn2{
    position: sticky;
    left:170px;
    background-color: #fff;
}
.freezedBody1{
    position: sticky;
    left: 0px;
}
.scrollableColumn{
    overflow: auto;
}
.fullWidth{
    width: 100%;
    justify-content: space-between;
}

/* transparent overlay container */
.overlayComponent{
    background-color: rgba(255,255,255,0.9);
}
  
  