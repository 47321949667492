.action {
    /* position: fixed; */
    top: 20px;
    right: 30px;
  }
  
  .action .profile {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .action .profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .action .menu {
    position: absolute;
    top: 120px;
    right: -10px;
    background: #fff;
    width: 220px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    /* border-radius: 15px; */
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
  }
  
  .action .menu.active {
    top: 54px;
    visibility: visible;
    opacity: 1;
    z-index: 9999;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 16px;
  
  }
  
  .menu ul{
    padding-left: 0rem;
    margin-top: 10px;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  
  .action .menu::before {
    content: "";
    position: absolute;
    top: -24px;
    right: 30px;
    width: 20px;
    height: 20px;
    background: transparent;
    /* transform: rotate(45deg); */
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  
  .action .menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
    color: #555;
    line-height: 1.5em;
  }
  
  .action .menu h3 span {
    font-size: 14px;
    color: #cecece;
    font-weight: 300;
  }
  
  .action .menu ul li {
    list-style: none;
    padding: 10px 15px;
    align-items: center;
  }
  .action .menu ul li:first-child {
    border-top: 0px;
  }
  
  .action .menu ul li img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
  }
  
  .action .menu ul li:hover img {
    opacity: 1;
  }
  
  .action .menu ul li a {
    display: inline-block;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
    font-size: 15px;
  }
  
  .action .menu ul li:hover a {
    color: #fff;
  }

  .action .menu ul li i {
    padding-right: 10px;
  }

  .action .menu li{
    background: #F7F7F7;
    margin-bottom: 3px;
    text-align: center;
  }

  .action .menu ul li:hover {
      background: #C51852;
      color: #fff;
  }
  #sign-out-link{
    margin: auto;
    font-weight: bold;
  }

  .action .menu ul li a {
    display: block;
  }
  

  /* when screen is lower than 1200px */
  @media  (min-width:1110px) and (max-width:1200px) {
    .action .menu{
      position: fixed;
    }

  }